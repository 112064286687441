
import styled, {css} from 'styled-components'
import PropTypes from 'prop-types'
import {isEmpty} from 'lodash-es'
import {media} from '../../../utils'
import {ContentContainer} from '../../base'
import {Box, Button} from '..'


const StyledLink = styled(Button)`
  ${media.down('mobile')(css`
    justify-self: center;
  `)}
`

const StyledImageButton = styled(Button)`
  background-color: ${({theme}) => theme.colors.background};
  padding: 1.25rem 1.5rem;
`

const MaterialItem = ({title, image, content, linkText, linkUri, imageButton, ...props}) => (
  <ContentContainer {...props}>
    <Box
        title={title}
        image={image}
        description={content}
        footer={
          <If condition={!isEmpty(title) && !isEmpty(linkUri) && !isEmpty(linkText)}>
            <StyledLink type="text" to={linkUri}>
              {linkText}
            </StyledLink>
          </If>
        }
        imageButton={
          <If condition={!isEmpty(imageButton?.url) && !isEmpty(imageButton?.title)}>
            <StyledImageButton to={imageButton?.url}>
              {imageButton?.title}
            </StyledImageButton>
          </If>
        }
    />
  </ContentContainer>
)

MaterialItem.propTypes = {
  content: PropTypes.string,
  image: PropTypes.object,
  title: PropTypes.string,
  linkText: PropTypes.string,
  linkUri: PropTypes.string,
  imageButton: PropTypes.string,
}

export default MaterialItem
