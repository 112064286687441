import {isEmpty, orderBy} from 'lodash-es'
import PropTypes from 'prop-types'
import {normalizeId} from '../../../utils'
import MaterialItem from './MaterialItem'


const MaterialsList = ({materials}) => (
  <If condition={!isEmpty(materials)}>
    <For each="material" of={orderBy(materials, ['materials.priority'])} index="idx">
      <If condition={material.material}>
        <Choose>
          <When condition={!isEmpty(material.material?.title)}>
            <MaterialItem
                id={normalizeId(material.material?.title)}
                key={idx}
                title={material.material?.title}
                image={material.material?.featuredImage?.node}
                content={material.material?.content}
                linkText={material.material?.materials?.portfolioText}
                linkUri={
                  material.material?.materials?.materialLink
                    ? material.material?.materials?.materialLink
                    : material.material?.materials?.portfolioLink?.url}
            />
          </When>
          <Otherwise>
            <MaterialItem
                key={idx}
                image={material.material?.featuredImage?.node}
                content={material.material?.content}
                imageButton={material.material?.materials?.portfolioLink}
            />
          </Otherwise>
        </Choose>
      </If>
    </For>
  </If>
)

MaterialsList.propTypes = {
  materials: PropTypes.array.isRequired,
}

export default MaterialsList
