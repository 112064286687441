import PropTypes from 'prop-types'
import {graphql} from 'gatsby'
import {isEmpty} from 'lodash-es'
import {AppShell, Experience, Hero, MaterialsList} from '../components/custom'
import {ContentContainer} from '../components/base'
import WpContent, {DefaultWrapper} from '../components/base/WpContent/lazy'
import SSRSuspense from '../components/custom/SSRSuspense/SSRSuspense'
import {getLangToSlug} from '../utils'


const Materials = ({data, pageContext: {lang}}) => {
  const {
    title, featuredImage, content, seo, translations, uri, template: {sectionMaterials: {selectedMaterials}},
  } = data.wpPage
  const {template: {sectionMaterials: {experienceMaterials}}} = data.wpPage
  const fallBack = (<DefaultWrapper>{content}</DefaultWrapper>)

  return (
    <AppShell title={title} seo={seo} lang={lang} langToSlug={getLangToSlug({translations, uri, lang})}>
      <Hero url={featuredImage?.node?.localFile?.url} title={title} />
      <If condition={Boolean(content)}>
        <ContentContainer>
          <SSRSuspense fallback={fallBack}>
            <WpContent>
              {content}
            </WpContent>
          </SSRSuspense>
        </ContentContainer>
      </If>
      <If condition={!isEmpty(selectedMaterials)}>
        <MaterialsList materials={selectedMaterials} />
      </If>
      <Experience
          text={experienceMaterials?.experienceText}
          photo={experienceMaterials?.experiencePhoto}
          video={experienceMaterials?.experienceVideoLink}
      />
    </AppShell>
  )
}

export const data = graphql`
  query MaterialsPage($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      slug
      translations {
        href
        locale
      }
      uri
      content
      seo {
        metaDesc
        opengraphDescription
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          mediaItemUrl
        }
        twitterImage {
          mediaItemUrl
        }
      }
      featuredImage {
        node {
          localFile {
            url
          }
        }
      }
      template {
        ... on WpTemplate_Materialy {
          sectionMaterials {
            experienceMaterials {
              experienceText
              experienceVideoLink {
                url
                title
              }
              experiencePhoto {
                sourceUrl
                altText
              }
            }
            selectedMaterials {
              material {
                ... on WpMaterial {
                  title
                  content
                  materials {
                    priority
                    materialLink
                    portfolioText
                    portfolioLink {
                      url
                      title
                    }
                  }
                  featuredImage {
                    node {
                      altText
                      srcSet
                      sourceUrl
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

Materials.propTypes = {
  data: PropTypes.object.isRequired,
  pageContext: PropTypes.object,
}

export default Materials
